<template>
    <div class="wrapper">
        <DomainsFilters></DomainsFilters>
        <DomainsTable></DomainsTable>
    </div>
</template>

<script>
  import DomainsFilters from "@/components/Admin/Domains/DomainsFilters";
  import DomainsTable from "@/components/Admin/Domains/DomainsTable";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { GET_DOMAINS, UPDATE_DOMAINS_PAGINATION } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_FILTERS } from "@core/store/mutation-constants";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "Domains",

    mixins: [DebounceUpdateByWatchedParams],

    components: { DomainsTable, DomainsFilters },

    created () {
      const {
        name,
        sslAuthMethod,
        webmasterId,
        certificateStatus,
        page,
        perPage
      } = this.$route.query;

      const pagination = {
        ...perPage && { perPage: +perPage },
        ...page && { page: +page }
      };

      const filters = {
        ...name && { name },
        ...sslAuthMethod && { sslAuthMethod },
        ...webmasterId && { webmasterId },
        ...certificateStatus && { certificateStatus }
      };

      this.UPDATE_FILTERS(filters);
      this.UPDATE_DOMAINS_PAGINATION(pagination);
    },

    computed: {
      ...mapState("admin/domains", {
        updateParams: ({ filters, pagination }) => ({ ...filters, ...pagination })
      })
    },

    methods: {
      ...mapActions("admin/domains", [
        UPDATE_DOMAINS_PAGINATION,
        UPDATE_FILTERS,
        GET_DOMAINS,
        SET_EMPTY
      ]),

      async updated () {
        await this.GET_DOMAINS();

        this.$router.push({
          query: this.updateParams
        }).catch(_ => {});
      }
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped></style>

<template>
    <div class="columns is-multiline">
        <div class="column is-2">
            <FField :label="$t('admin.domains.filters.name')">
                <FInput
                    v-model="name"
                    :placeholder="$t(`admin.domains.filters.name`)">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.domains.filters.webmaster')">
                <FSelect
                    v-model="webmasterId"
                    :placeholder="$t(`admin.domains.filters.webmaster`)"
                    :get-data-vuex="getWebmasters"
                    class="white"
                    field="login">
                    <template #text="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                    <template #selected-option="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                </FSelect>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.domains.filters.sslAuthMethod')">
                <FSelect
                    v-model="sslAuthMethod"
                    :placeholder="$t(`admin.domains.filters.sslAuthMethod`)"
                    :get-data-vuex="getSslAuthMethod"
                    class="white"
                    field="label"
                    prop="value">
                </FSelect>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.domains.filters.certificateStatus')">
                <FSelect
                    v-model="certificateStatus"
                    :placeholder="$t(`admin.domains.filters.certificateStatus`)"
                    :get-data-vuex="getCertificateStatus"
                    class="white"
                    field="label"
                    prop="value">
                    <template #text="{ option }">
                        <span v-t="option.label"></span>
                    </template>
                    <template #selected-option="{ option }">
                        <span v-t="option.label"></span>
                    </template>
                </FSelect>
            </FField>
        </div>

        <div class="column has-text-right">
            <!-- eslint-disable-next-line no-irregular-whitespace -->
            <FField label=" ">
                <FButton
                    plain
                    no-more
                    type="success"
                    @click="openModal">
                    {{ $t("admin.domains.filters.buttons.addDomain") }}
                </FButton>
            </FField>
        </div>
    
        <DomainsNewDomainModal
            :is-active.sync="isNewDomainModal"
            @update="emitUpdate">
        </DomainsNewDomainModal>
    </div>
</template>

<script>
  import sslAuthMethodList from "@core/store/modules/common/domains/datasets/sslAuthMethod.json";
  import certificateStatus from "@core/store/modules/common/domains/datasets/certificateStatus.json";
  import DomainsNewDomainModal from "@/components/Admin/Domains/DomainsNewDomainModal";
  import UserOutput from "@/components/Common/UserOutput";
  import FButton from "@/components/Common/FButton";
  import FSelect from "@/components/Common/FSelect";
  import FInput from "@/components/Common/FInput";
  import FField from "@/components/Common/FField";
  import { Filters } from "@core/mixins";
  import { GET_INITIAL_WEBMASTERS, GET_WEBMASTERS_LIST } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import {
    UPDATE_WEBMASTERS_FILTERS,
    UPDATE_WEBMASTERS_LIST_PAGINATION,
    UPDATE_FILTERS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";

  export default {
    name: "DomainsFilters",
    mixins: [Filters],
    components: {
      DomainsNewDomainModal,
      UserOutput,
      FField,
      FButton,
      FSelect,
      FInput
    },
    data () {
      return {
        isNewDomainModal: false
      };
    },

    computed: {
      ...mapState("admin/domains", {
        webmastersList: state => state.webmastersList.data
      }),

      ...mapFields("admin/domains", {
        fields: [
          "name",
          "webmasterId",
          "sslAuthMethod",
          "certificateStatus"
        ],
        base: "filters",
        action: UPDATE_FILTERS
      })
    },

    methods: {
      ...mapMutations("admin/domains/webmastersList", [
        UPDATE_WEBMASTERS_LIST_PAGINATION,
        UPDATE_WEBMASTERS_FILTERS
      ]),

      ...mapActions("admin/domains/webmastersList", [
        GET_WEBMASTERS_LIST,
        GET_INITIAL_WEBMASTERS
      ]),

      async getWebmasters (label = "") {
        this.UPDATE_WEBMASTERS_LIST_PAGINATION();
        this.UPDATE_WEBMASTERS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });

        if (this.webmastersList?.items?.length > 0) {
          await this.GET_WEBMASTERS_LIST();
        } else {
          const res = await this.GET_INITIAL_WEBMASTERS(this.$route.query[this.routeParamName]);

          if (res?.length > 0) {
            this.webmasterId = res[0];
          }
        }

        return this.webmastersList;
      },

      getSslAuthMethod () {
        return {
          items: sslAuthMethodList,
          count: sslAuthMethodList.length
        };
      },

      getCertificateStatus () {
        return {
          items: certificateStatus,
          count: certificateStatus.length
        };
      },

      openModal () {
        this.isNewDomainModal = true;
      }
    }
  };
</script>

<style scoped>
    .w-full {
        width: 10.5rem;
    }
</style>

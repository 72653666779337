<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoadingLocal"
        :model="{ name }"
        @close="closeModal"
        @submit="addDomain">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.domains.modal.header.addDomain") }}
            </h5>
        </template>

        <section>
            <div class="mb-5">
                <FField :label="$t('admin.domains.modal.body.domainName')">
                    <template #label>
                        <div class="is-flex pt-1">
                            {{ $t('admin.domains.modal.body.domainName') }}
                            <LabelWithAnnotation :tooltip="$t(`admin.domains.modal.messages.domainName`)">
                            </LabelWithAnnotation>
                        </div>
                    </template>
                    
                    <RequiredField
                        v-model="name"
                        :placeholder="$t('admin.domains.modal.body.domainName')"
                        :pattern-message="$t('admin.domains.modal.notifications.invalidName')"
                        :pattern="DOMAIN_NAME_VALIDATOR"
                        prop="name">
                    </RequiredField>
                </FField>
            </div>

            <div class="mb-5">
                <FField :label="$t('admin.domains.modal.body.sslAuthMethod')">
                    <FSelect
                        v-model="sslAuthMethod"
                        :placeholder="$t(`admin.domains.modal.body.sslAuthMethod`)"
                        :get-data-vuex="getSslAuthMethod"
                        required
                        class="white"
                        field="label"
                        prop="value">
                    </FSelect>
                </FField>
            </div>

            <div>
                <FField :label="$t('admin.domains.modal.body.webmaster')">
                    <FSelect
                        v-model="webmasterId"
                        :placeholder="$t(`admin.domains.modal.body.webmaster`)"
                        :get-data-vuex="getWebmasters"
                        class="white"
                        field="login"
                        prop="id">
                        <template #text="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                    </FSelect>
                </FField>
            </div>
        </section>

        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column w-full">
                        <FButton
                            plain
                            no-more
                            :loading="isLoadingLocal"
                            class="is-fullwidth"
                            native-type="submit"
                            type="success">
                            {{ $t("common.buttons.save") }}
                        </FButton>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import sslAuthMethodList from "@core/store/modules/common/domains/datasets/sslAuthMethod.json";
  import RequiredField from "@/components/Common/RequiredField";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import ModalBox from "@/components/Common/ModalBox";
  import { Modal } from "@core/mixins";
  import { CRM } from "@core/mixins";
  import { DOMAIN_NAME_VALIDATOR } from "@core/validators";
  import {
    ADD_DOMAIN,
    GET_DOMAINS,
    UPDATE_OPTIONS,
    GET_WEBMASTERS_LIST,
    GET_INITIAL_WEBMASTERS,
    SET_EMPTY
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState, mapMutations } from "vuex";
  import { i18n } from "@core/plugins";
  import { UPDATE_WEBMASTERS_FILTERS, UPDATE_WEBMASTERS_LIST_PAGINATION } from "@core/store/mutation-constants";
  import FButton from "@/components/Common/FButton";
  import FField from "@/components/Common/FField";
  import LabelWithAnnotation from "@/components/Common/LabelWithAnnotation";

  export default {
    name: "DomainsNewDomainModal",
    mixins: [Modal, CRM],
    components: {
      LabelWithAnnotation,
      FField,
      UserOutput,
      FButton,
      ModalBox,
      RequiredField,
      FSelect
    },

    computed: {
      ...mapState("admin/domains/domainModal", {
        webmastersList: state => state.webmastersList.data
      }),

      ...mapFields("admin/domains/domainModal", {
        fields: [
          "name",
          "sslAuthMethod",
          "webmasterId"
        ],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      isLoadingLocal () {
        return this.$wait(`admin/domains/domainModal/${ ADD_DOMAIN }`);
      },

      DOMAIN_NAME_VALIDATOR () {
        return DOMAIN_NAME_VALIDATOR;
      }
    },

    methods: {
      ...mapMutations("admin/domains/domainModal/webmastersList", [
        UPDATE_WEBMASTERS_LIST_PAGINATION,
        UPDATE_WEBMASTERS_FILTERS
      ]),

      ...mapActions("admin/domains/domainModal/webmastersList", [
        GET_WEBMASTERS_LIST,
        GET_INITIAL_WEBMASTERS
      ]),

      ...mapActions("admin/domains/domainModal", [
        UPDATE_OPTIONS,
        ADD_DOMAIN,
        SET_EMPTY
      ]),

      ...mapActions("admin/domains", [
        GET_DOMAINS
      ]),

      addQuery () {
        const item = [{ key: "", value: "" }];
        this.localeQuery = [
          ...this.localeQuery,
          ...item
        ];
      },

      deleteQuery (item) {
        const idx = this.localeQuery.indexOf(item);
        this.localeQuery.splice(idx, 1);
      },

      closeModal () {
        this.isModalActive = false;
        this.SET_EMPTY();
      },

      async getWebmasters (label = "") {
        this.UPDATE_WEBMASTERS_LIST_PAGINATION();
        this.UPDATE_WEBMASTERS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });

        if (this.webmastersList?.items?.length > 0) {
          await this.GET_WEBMASTERS_LIST();
        } else {
          const res = await this.GET_INITIAL_WEBMASTERS(this.$route.query[this.routeParamName]);

          if (res?.length > 0) {
            this.webmasterId = res[0];
          }
        }

        return this.webmastersList;
      },

      async addDomain () {
        try {
          await this.ADD_DOMAIN();

          this.emitUpdate();
          this.closeModal();

          this.$buefy.toast.open({
            message: this.$t("admin.domains.modal.messages.confirmationCreate"),
            type: "is-success"
          });

          await this.GET_DOMAINS();
        } catch ({ errors }) {
          //TODO: Вынести Метод translatedName из компонента NameTranslationOutput и подключить глобально
          const translatedName = `common.messages.domain.${ errors[0].message }`;

          if(i18n.te(translatedName)) {
            this.$buefy.toast.open({
              message: i18n.t(translatedName),
              type: "is-danger"
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("common.files.toasts.fail"),
              type: "is-danger"
            });
          }
        }
      },

      getSslAuthMethod () {
        return {
          items: sslAuthMethodList,
          count: sslAuthMethodList.length
        };
      }
    },
    
    watch: {
      isExternal () {
        this.parent = null;
      }
    }
  };
</script>

<style scoped>
</style>

<template>
    <FTable
        :data="domainsItems"
        :is-loading="isLoading"
        :current-page="page"
        :per-page.sync="perPage"
        :total="domainsCount"
        @update:currentPage="updateCurrentPage($event)"
        @update:perPage="updatePerPage($event)">
        <el-table-column
            v-slot="{ row }"
            :label="$t(`admin.domains.table.labels.name`)">
            {{ formatEmptyString(row.name) }}
        </el-table-column>

        <el-table-column
            v-slot="{ row }"
            :label="$t(`admin.domains.table.labels.webmaster`)">
            <UserOutput :user="row.webmaster"></UserOutput>
        </el-table-column>

        <el-table-column>
            <template #header>
                <LabelWithAnnotation
                    :tooltip="$t(`admin.domains.table.tooltip.ssl`)"
                    tooltip-position="is-top"
                    type="is-info">
                    {{ $t(`admin.domains.table.labels.sslAuthMethod`) }}
                </LabelWithAnnotation>
            </template>
            <template #default="{ row }">
                {{ formatEmptyString(row.sslAuthMethod) }}
            </template>
        </el-table-column>

        <el-table-column
            v-slot="{ row }"
            :label="$t(`admin.domains.table.labels.certificateStatus`)">
            {{ formatEmptyString(
                $t(`common.entity.domains.filters.values.certificateStatusEnum.${ row.certificateStatus }`)
            ) }}
        </el-table-column>

        <el-table-column
            v-slot="{ row }"
            :label="$t(`admin.domains.table.labels.date`)">
            {{ formatEmptyString(date(row.createdAt)) }}
        </el-table-column>

        <el-table-column
            v-slot="{ row }"
            width="120"
            :label="$t(`admin.domains.table.labels.action`)">
            <el-tooltip
                placement="top"
                :content="$t(`common.entity.tileItem.tooltip.delete`)">
                <div class="w-10 mt-3">
                    <FButton
                        plain
                        no-more
                        type="danger"
                        icon="el-icon-delete"
                        class="button"
                        @click="deleteDomain(row)">
                    </FButton>
                </div>
            </el-tooltip>
        </el-table-column>

        <template slot="empty">
            {{ $t(`common.table.noData`) }}
        </template>
    </FTable>
</template>

<script>
  import LabelWithAnnotation from "@/components/Common/LabelWithAnnotation";
  import UserOutput from "@/components/Common/UserOutput";
  import FTable from "@/components/Common/FTable";
  import FButton from "@/components/Common/FButton";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { DELETE_DOMAIN, GET_DOMAINS, UPDATE_DOMAINS_PAGINATION } from "@core/store/action-constants";
  import { formatEmptyString } from "@core/filters";
  import { TableVuex } from "@core/mixins/tableVuex";
  import { mapActions, mapState } from "vuex";
  import momentjs from "moment";

  export default {
    name: "DomainsTable",
    mixins: [TableVuex],
    components: {
      LabelWithAnnotation,
      UserOutput,
      FTable,
      FButton
    },

    computed: {
      ...mapFields("admin/domains", {
        fields: ["page", "perPage"],
        base: "pagination",
        action: UPDATE_DOMAINS_PAGINATION
      }),

      ...mapState("admin/domains", {
        domainsItems: ({ domains }) => domains?.items,
        domainsCount: ({ domains }) => domains?.count
      }),

      isLoading () {
        return this.$wait(`admin/domains/${ GET_DOMAINS }`)
          || this.$wait(`admin/domains/${ DELETE_DOMAIN }`);
      }
    },

    methods: {
      ...mapActions("admin/domains", [
        DELETE_DOMAIN,
        GET_DOMAINS
      ]),

      async infiniteHandler ($state) {
        this.page += 1;
        await this.GET_DOMAINS();
        $state.loaded();
      },
      
      async deleteDomain ({ id }) {
        const { dialog } = await this.$buefy.dialog.confirm({
          title: this.$t("common.entity.confirm.header.delete"),
          message: this.$t("common.entity.confirm.body.delete"),
          confirmText: this.$t("common.entity.confirm.footer.confirmDelete"),
          cancelText: this.$t("common.entity.confirm.footer.cancel"),
          type: "is-danger is-light",
          closeOnConfirm: false,
          onConfirm: async () => {
            try {
              await this.DELETE_DOMAIN(id);
              this.GET_DOMAINS();
              this.$buefy.toast.open({
                message: this.$t("common.entity.confirm.messages.confirmationDelete"),
                type: "is-success"
              });
            } catch {
              this.$buefy.toast.open({
                message: this.$t("common.entity.confirm.messages.failDelete"),
                type: "is-danger"
              });
            }
          }
        });

        dialog.close();
      },

      date (date) {
        return momentjs(date).format("DD.MM.YYYY HH:mm");
      },

      formatEmptyString
    }
  };
</script>

<style></style>
